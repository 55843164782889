import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  title?: string
  description?: string
  image?: ImageProps
  languageCode: string
}

export const SpaPack = memo(function SpaPack({
  title,
  description,
  image,
  languageCode,
}: Props) {
  return (
    <Container>
      <StyledImage {...image} />
      <Overlay />
      <Text>
        {title ? <Title>{title}</Title> : null}
        {description ? <Description>{description}</Description> : null}
        <ButtonsList>
          <StyledButton
            variant="outline-light"
            target="_blank"
            label={useVocabularyData('reserve', languageCode)}
            URL={useVocabularyData('booking-spa-url', languageCode)}
          />
          <StyledButton
            variant="outline-light"
            label={useVocabularyData('request', languageCode)}
            URL="#form--spa"
          />
        </ButtonsList>
      </Text>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  &:hover {
    & img {
      transform: scale(1.1);
    }
  }
`

const StyledImage = styled(Image)`
  position: relative;
  width: 100%;
  height: 100%;
  display: block;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 50%
  );
`

const Text = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 4vw;
  padding-bottom: 4vw;
`

const Title = styled.h3`
  font-size: 1.66vw;
  font-weight: 300;
  margin-bottom: 0.5vw;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.variants.neutralLight5};

  @media (max-width: 991px) {
    font-size: 18px;
    margin-bottom: 5px;
  }
`
const Description = styled.p`
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
`

const ButtonsList = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1vw;
`
const StyledButton = styled(Button)`
  text-transform: none;
  font-weight: 300;
  width: 50%;
  display: block;
  max-width: none;
  text-align: left;
  margin-right: 22px;

  @media (max-width: 991px) {
    padding: 5px;
  }
`
