import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo, useState } from 'react'

export interface Props {
  slug?: string
  title?: string
  subtitle?: string
  image?: ImageProps
  languageCode: string
}

export const Deal = memo(function Deal({
  slug,
  title,
  subtitle,
  image,
  languageCode,
}: Props) {
  return (
    <Container>
      <Link to={slug || ''}>
        <StyledImage {...image} />
        <Overlay />
        <Text>
          {title ? <Title>{title}</Title> : null}
          {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
          <ButtonsList>
            <DealCTA>
              {useVocabularyData('details', languageCode)}
              <StyledArrowCTA direction="R" />
            </DealCTA>
          </ButtonsList>
        </Text>
      </Link>
    </Container>
  )
})

const StyledImage = styled(Image)`
  position: relative;
  width: 100%;
  height: 100%;
  display: block;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.1);
    transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 50%
  );
`

const Text = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 4vw;
  padding-bottom: 4vw;
  transition: all 600ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
`

const Title = styled.h3`
  font-size: 1.66vw;
  font-weight: 400;
  margin-bottom: 0.5vw;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.variants.neutralLight5};

  @media (max-width: 991px) {
    font-size: 18px;
    margin-bottom: 5px;
  }
`
const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.variants.neutralLight5};

  @media (max-width: 991px) {
    font-size: 16px;
    margin-bottom: 5px;
  }
`

const ButtonsList = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1vw;
`

const DealCTA = styled.div`
  position: relative;
  display: inline-block;
  padding: 0.5vw 0;
  padding-right: 1vw;
  font-size: 0.9vw;
  letter-spacing: 2px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.variants.neutralLight5};

  &::after,
  &::before {
    display: block;
    content: ' ';
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.5;
    z-index: 2;
    background: ${({ theme }) => theme.colors.variants.neutralLight5};
  }

  &::after {
    width: 0;
    opacity: 1;
    transition: all 600ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  @media (max-width: 991px) {
    display: none;
  }
`
const StyledArrowCTA = styled(Arrow)`
  position: absolute;
  top: 50%;
  right: 0;
  background: none;
  transform: translate3d(50%, -50%, 0) scale(0.8);
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  &:hover {
    & img {
      transform: scale(1);
    }
    ${Text} {
      padding-bottom: 5.2vw;
    }
    ${DealCTA} {
      &::after {
        width: 100%;
      }
    }
  }
`
