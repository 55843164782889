import styled from '@emotion/styled'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Arrow } from 'app/components/Common/Arrow'
import { Lines } from 'app/components/Common/Lines'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useState } from 'react'

import { Deal, Props as DealProps } from './deal'
import { Props as SpaPackProps, SpaPack } from './spa_pack'

export interface Props {
  pretitle?: string
  title?: string
  spaPacks?: SpaPackProps[]
  deals?: DealProps[]
  languageCode: string
}

export const ElemsSlider = memo(function ElemsSlider({
  pretitle,
  title,
  spaPacks,
  deals,
  languageCode,
}: Props) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [sliderMain, instanceRefMain] = useKeenSlider({
    loop: true,
    initial: 0,
    slides: { perView: 2, spacing: 45 },
    defaultAnimation: {
      duration: 1500,
    },
    breakpoints: {
      '(max-width: 991px)': {
        slides: { perView: 1.2, spacing: 5, origin: 'center' },
        loop: false,
        drag: true,
      },
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
      let index = s.track.details.rel
    },
  })
  return (
    <Container>
      <Lines />
      <Top>
        {pretitle ? (
          <FadeInUp>
            <Pretitle dangerouslySetInnerHTML={{ __html: pretitle }} />
          </FadeInUp>
        ) : null}
        {title ? <Title text={title} /> : null}
      </Top>
      <SliderWrapper>
        <FadeInUp>
          <Slider ref={sliderMain} className="keen-slider">
            {spaPacks && spaPacks.length > 0
              ? spaPacks?.map((item, index) => (
                  <Slide key={index} className="keen-slider__slide">
                    <SpaPack {...item} />
                  </Slide>
                ))
              : null}
            {deals && deals.length > 0
              ? deals?.map((item, index) => (
                  <Slide key={index} className="keen-slider__slide">
                    <Deal {...item} />
                  </Slide>
                ))
              : null}
          </Slider>
          {(spaPacks && spaPacks?.length > 2) ||
          (deals && deals?.length > 2) ? (
            <Arrow
              className="arrow-left"
              onClick={(e) => {
                instanceRefMain.current?.prev()
              }}
            />
          ) : null}
          {(spaPacks && spaPacks?.length > 2) ||
          (deals && deals?.length > 2) ? (
            <Arrow
              className="arrow-right"
              direction="R"
              onClick={(e) => {
                instanceRefMain.current?.next()
              }}
            />
          ) : null}
        </FadeInUp>
      </SliderWrapper>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  width: 100%;
  padding: 6.66vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};

  @media (max-width: 991px) {
    padding: 30px;
  }
`

const Top = styled.div``

const Pretitle = styled.h3`
  font-size: 0.9vw;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};

  @media (max-width: 1439px) {
    font-size: 13px;
  }
`
const Title = styled(AnimatedTitle)`
  font-size: 3vw;
  margin: 3vw 0 0 0;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.heading};

  @media (max-width: 991px) {
    margin-top: 20px;
    font-size: 28px;
  }
`

const SliderWrapper = styled.div`
  position: relative;
  margin-top: 4vw;

  .arrow-left {
    top: 50%;
    left: 0;
    position: absolute;
    border: 4px solid ${({ theme }) => theme.colors.variants.neutralLight3};
    transform: translate3d(-50%, -50%, 0);
  }
  .arrow-right {
    top: 50%;
    right: 0;
    position: absolute;
    border: 4px solid ${({ theme }) => theme.colors.variants.neutralLight3};
    transform: translate3d(50%, -50%, 0);
  }

  @media (max-width: 991px) {
    width: 100vw;
    margin-left: -30px;

    .arrow-left,
    .arrow-right {
      display: none;
    }
  }
`
const Slider = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  height: 30vw;

  @media (max-width: 991px) {
    height: 60vw;
  }
`
const Slide = styled.div`
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
`
